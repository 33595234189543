import { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Button, Col, Container, Form, Pagination, ProgressBar, Row, Table } from 'react-bootstrap'
import { useSpinner } from '../../../spinner/SpinnerContext'
import { InteractiveBrowserCredential } from '@azure/identity'
import { BlobServiceClient } from '@azure/storage-blob'
import { TENANT_ID, CLIENT_ID } from '../../../authConfig'
import { DATA_SERVICES_BLOBS } from '../../../common/common.constants'
import { FileUploader } from 'react-drag-drop-files'
import { blobToString } from '../../../blobs/blobs.service'
import { mapFedexToCarrierInvoices } from './fedex.helpers'
import { mapUpsToCarrierInvoices } from './ups.helpers'
import { CarrierInvoice, CarrierInvoiceAuditSave, CarrierInvoiceShipDetail, CarrierInvoiceTableHeaders, CostAuditProduct, GroupedCarrierInvoice, WarehouseTotal } from './carrier.invoice.audit.interfaces'
import { flattenObject, sortByFields } from '../../../common/common.helpers'
import { notify } from '../../../notify/notify'
import { DateTime } from 'luxon'
import * as XLSX from "xlsx"
import React from 'react'

/**
 * This component accepts UPS and Fedex invoice files and maps them to related ship details by order number.
 * Users can mark the invoice lines as disputed or ignored, turning the undisputed invoices into a kind of queue to work out of.
 * This refactor will save the details as the user works so that when the page is interrupted, it can be easily reloaded without the hassle of reloading all of the data over again.
 */
const CarrierInvoiceAuditComponent = () => {
  const { showSpinner, hideSpinner } = useSpinner()

  const [carrier, setCarrier] = useState(undefined)
  const [workMode, setWorkMode] = useState(undefined)
  const [saves, setSaves] = useState(undefined)
  const [fileUpload, setFileUpload] = useState(undefined)
  const [invoices, setInvoices] = useState(undefined)
  const [saveData, setSaveData] = useState(undefined)
  const [products, setProducts] = useState(undefined)
  const [workData, setWorkData]: [CarrierInvoiceAuditSave, any] = useState(undefined)
  const [itemsPerPage, setItemsPerPage] = useState(350)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentFile, setCurrentFile] = useState(undefined)
  const [exportedFile, setExportedFile] = useState(undefined)
  const [exportedFileUrl, setExportedFileUrl] = useState(undefined)
  const [exportFiltered, setExportFiltered] = useState(undefined)
  const [exportFilteredTotals, setExportFilteredTotals] = useState(undefined)

  /** Filters */
  const [uniqueWarehouses, setUniqueWarehouses] = useState(undefined)
  const [hideUnmatched, setHideUnmatched] = useState(true)
  const [filterWarehouse, setFilterWarehouse] = useState('all')
  const [searchValue, setSearchValue] = useState(undefined)
  const [differenceType, setDifferenceType] = useState('percent')
  const [differenceValue, setDifferenceValue] = useState('10')

  const carrierSavesPath = `audit/saves`
  const carrierExportsPath = `audit/exports/xlsx`
  const xlsxFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  
  const salesforceUrl = 'https://nethealthshopsllc.lightning.force.com'

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })
  const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS, credential)
  const containerService = blobServiceClient.getContainerClient('storage')

  /** Load Product Data */
  useEffect(() => {
    const loadProducts = async () => {
      showSpinner()
      try {
        const blobClient = containerService.getBlobClient(`product-service/products/cost-audit-products.json`)
        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        const json = JSON.parse(downloaded)
        setProducts(json)
      }catch(error){
        console.error(error)
      }
      hideSpinner()
    }
    loadProducts()
  }, [])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleCarrierChanged = useCallback((e) => {
    if(['fedex', 'ups'].includes(e.target.value)){
      setCarrier(e.target.value)
    }
  }, [])

  const carrierSelectionView = useMemo(() => {
    return (
      <>
        <Form>
          <Form.Select onChange={handleCarrierChanged}>
            <option>Will you be working on FedEx or UPS?</option>
            <option value='fedex'>FedEx</option>
            <option value='ups'>UPS</option>
          </Form.Select>
      </Form>
      </>
    )
  }, [handleCarrierChanged])

  const handleFileChanged = useCallback((file) => {
    if(!file) return
    if(!carrier) return
    showSpinner()

    try {
      const contentReader = new FileReader()
      if (file) contentReader.readAsText(file)

      contentReader.onload = (e) => {
        let mappedInvoices = undefined
        if(carrier === 'fedex'){
          mappedInvoices = mapFedexToCarrierInvoices(e.target.result as string)
        } else if(carrier === 'ups'){
          mappedInvoices = mapUpsToCarrierInvoices(e.target.result as string)
        }
        setFileUpload(file.name)
        setInvoices(mappedInvoices)
      }
    }catch(error){
      console.error(error)
    }
    hideSpinner()
  }, [carrier])

  const dragDropAreaView = useMemo(() => {
    const dragDrop = (
      <div style={{borderStyle: 'dashed', borderRadius: '4px', padding: '5px', width: '100%', maxWidth: '100%', height: '40px', textAlign: 'center', color: 'darkgreen'}}>
        Drag & Drop / Upload File
      </div>
    )
    return (
      <>
        <hr/>
        <FileUploader handleChange={handleFileChanged} name="file" label="Upload, or drag and drop files here..." children={dragDrop} />
      </>
    )
  }, [carrier])

  const handleWorkModeChanged = useCallback((e) => {
    if(['new', 'load'].includes(e.target.value)){
      setWorkMode(e.target.value)
    }
  }, [])

  const workModeView = useMemo(() => {
    return (
      <>
        <hr/>
        <Form>
          <Form.Select onChange={handleWorkModeChanged}>
            <option>Will you be working on a new file or load an existing save?</option>
            <option value='new'>New</option>
            <option value='load'>Load</option>
          </Form.Select>
        </Form>
      </>
    )
  }, [handleWorkModeChanged])

  const handleLoadFromSaveChanged = useCallback((e) => {
    if(e.target.value === 'Select a saved file, or start the process by uploading invoices...') return
    const loadFromSave = async () => {
      showSpinner()

      try {
        const blobClient = containerService.getBlobClient(e.target.value)
        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        const json: CarrierInvoiceAuditSave = JSON.parse(downloaded)
        const warehouses = []
        for(const invoice of json.invoices){
          if(['undefined', 'null'].includes(typeof invoice.ShipDetail)){
            continue
          }
          if(!warehouses.includes(invoice.ShipDetail.Warehouse__r?.Name)){
            warehouses.push(invoice.ShipDetail.Warehouse__r.Name)
          }
        }
        setCurrentFile(e.target.value.split('/').pop())
        setUniqueWarehouses(warehouses)
        setWorkData(json)
      }catch(error){
        console.error(error)
      }

      hideSpinner()
    }
    loadFromSave()
  }, [carrier])

  const loadFromSaveView = useMemo(() => {
    if(!carrier) return

    if(!saves || saves.length === 0){
      return (
        <>
        <hr/>
        <div style={{color: 'darkred', fontSize: '16px', textAlign: 'center'}}>
          No save files found for {carrier.toUpperCase()}. Please start a new save by selecting "New" from the drop-down.
        </div>
        </>
      )
    }

    const options = []
    for(const save of saves){
      options.push(
        <option value={save}>{save.split('/').pop()}</option>
      )
    }

    return (
      <>
        <hr/>
        <Form>
          <Form.Select onChange={handleLoadFromSaveChanged}>
            <option>Select a saved file, or start the process by uploading invoices...</option>
            {options}
          </Form.Select>
        </Form>
      </>
    )
  }, [handleLoadFromSaveChanged, carrier, saves])

  /** Loads blobs from carrier saves path in audit/saves/{carrier} */
  useEffect(() => {
    const loadFiles = async () => {
      if(!carrier) return

      showSpinner()

      try {
        const blobPaths = []
        const blobs = containerService.listBlobsFlat({prefix: `${carrierSavesPath}/${carrier}/`})
        for await(const blob of blobs){
          blobPaths.push(blob.name)
        }
        setSaves(blobPaths)
      }catch(error){
        console.error(error)
      }

      hideSpinner()
    }
    loadFiles()
    
  }, [carrier])

  /** Loads Ship Details and pairs them with invoices by order number, salesforce id in the order number field, or the salesforce id in the invoice products field */
  useEffect(() => {
    if(!carrier || !invoices || !products) return

    const loadShipDetails = async function() {
      showSpinner()

      try {
        const blobClient = containerService.getBlobClient(`audit/carrier-invoices/fedex-shipping-details-90-days.json`)

        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        const shipDetails: CarrierInvoiceShipDetail[] = JSON.parse(downloaded)
        
        const unknownBlobClient = containerService.getBlobClient(`audit/carrier-invoices/unknown-shipping-details-90-days.json`)
        const unknownData = await unknownBlobClient.download()
        const unknownDownloaded = await blobToString(await unknownData.blobBody)
        const unknownShipDetails: CarrierInvoiceShipDetail[] = JSON.parse(unknownDownloaded)

        shipDetails.concat(unknownShipDetails)

        const upsBlobClient = containerService.getBlobClient(`audit/carrier-invoices/ups-shipping-details-90-days.json`)
        const upsData = await upsBlobClient.download()
        const upsDownloaded = await blobToString(await upsData.blobBody)
        const upsShipDetails: CarrierInvoiceShipDetail[] = JSON.parse(upsDownloaded)

        shipDetails.concat(upsShipDetails)

        const save: CarrierInvoiceAuditSave = {
          invoices,
          totals: []
        }
        //Map shipdetail to invoice by order number
        for(const invoice of save.invoices){
          let invoiceOrderNumber = 'NOT_FOUND'
          if(typeof invoice.OrderNumber?.trim()?.toLowerCase() !== 'undefined' && invoice.OrderNumber?.trim()?.toLowerCase() !== ''){
            invoiceOrderNumber = invoice.OrderNumber?.trim()?.toLowerCase()
          }

          const shipDetail: CarrierInvoiceShipDetail = shipDetails.find(
            sd => 
              sd.Id?.toLowerCase() === invoice.Products?.trim()?.toLowerCase() ||
              sd.Id?.toLowerCase() === invoiceOrderNumber ||
              sd.Order_Number__c?.toLowerCase().includes(invoiceOrderNumber) ||
              invoiceOrderNumber.includes(sd.Order_Number__c?.toLocaleLowerCase())
          )

          if(typeof shipDetail !== 'undefined'){
            invoice.ShipDetail = shipDetail

            //Convert order time to readable format
            let orderTimeString = shipDetail?.Order__r?.Order_Time__c
            if(['undefined', 'null'].includes(typeof orderTimeString)){
              orderTimeString = ''
            } else {
              orderTimeString = DateTime.fromISO(orderTimeString).setZone('America/Chicago').toFormat('MM/dd/yyyy')
            }
            shipDetail.Order__r.orderTimeString = orderTimeString

            // Setup warehouse totals
            let warehouseTotal: WarehouseTotal = undefined
            let newTotal = false
            if(!['undefined', 'null'].includes(typeof shipDetail.Warehouse__r)){
              warehouseTotal = save.totals.find(t => t.warehouseName === shipDetail.Warehouse__r.Name)
              if(typeof warehouseTotal === 'undefined'){
                // New warehouse total
                warehouseTotal = {
                  warehouseName: shipDetail.Warehouse__r.Name,
                  invoiceTotal: 0,
                  productBudgetTotal: 0,
                  difference: 0
                }
                newTotal = true
              }
              warehouseTotal.invoiceTotal += invoice.NetDueNumber
            }
            
            invoice.ShipDetail.budgetTotal = 0
            //Map product data to order product by mpn
            let orderProductBudgetTotal = 0
            for(const orderProduct of invoice.ShipDetail.Order_Products__r){
              const product: CostAuditProduct = products.find(p => p.mpn === orderProduct.Product_MPN__c)
              if(typeof product !== 'undefined'){
                orderProduct.costAuditProduct = product
                let budget = carrier === 'fedex' ? orderProduct.costAuditProduct?.fedexBudget : orderProduct.costAuditProduct?.upsBudget
                warehouseTotal.productBudgetTotal += !budget ? (0 * orderProduct.Quantity) : budget * orderProduct.Quantity
                let mcBudgetText = `${orderProduct.Quantity}x ${orderProduct.Product_MPN__c} @ $${budget ?? 0} per is $${!budget ? (0 * orderProduct.Quantity) : budget * orderProduct.Quantity}`
                orderProduct.mcBudget = mcBudgetText
                orderProductBudgetTotal += budget * orderProduct.Quantity
              }
            }
            invoice.ShipDetail.budgetTotal += orderProductBudgetTotal
            if(newTotal){
              save.totals.push(warehouseTotal)
            }
          } else {
            // Get unmatched total
            let unmatchedTotal = save.totals.find(t => t.warehouseName === 'Unmatched')
            let newTotal = false
            if(typeof unmatchedTotal === 'undefined'){
              unmatchedTotal = {
                invoiceTotal: 0,
                productBudgetTotal: 0,
                warehouseName: 'Unmatched',
                difference: 0
              }
              newTotal = true
            }
            unmatchedTotal.invoiceTotal += invoice.NetDueNumber
            if(newTotal === true){
              save.totals.push(unmatchedTotal)
            }            
          }
        }

        //store difference in totals
        let overallBudgetTotal = 0
        let overallInvoiceTotal = 0
        for(const total of save.totals){
          total.difference += total.productBudgetTotal - total.invoiceTotal
          overallBudgetTotal += total.productBudgetTotal
          overallInvoiceTotal += total.invoiceTotal
        }

        save.totals.push({
          warehouseName: 'Overall Totals',
          invoiceTotal: overallInvoiceTotal,
          productBudgetTotal: overallBudgetTotal,
          difference: overallBudgetTotal - overallInvoiceTotal
        })

        //Sort by net due date desc
        const sortedInvoices = sortByFields(save.invoices, [{field: 'NetDueNumber', order: 'desc'},{field: 'OrderNumber', order: 'asc'}])
        save.invoices = sortedInvoices
        const warehouses = []
        for(const invoice of save.invoices){
          if(['undefined', 'null'].includes(typeof invoice.ShipDetail)){
            continue
          }
          if(!warehouses.includes(invoice.ShipDetail.Warehouse__r?.Name)){
            warehouses.push(invoice.ShipDetail.Warehouse__r.Name)
          }
        }
        setUniqueWarehouses(warehouses)
        setSaveData(save)
        
      }catch(error){
        console.error(error)
      }

      hideSpinner()
    }
    loadShipDetails()
  }, [carrier, invoices, products])

  /** Saves content after saveData is updated */
  useEffect(() => {
    const saveContent = async function(){
      if(!saveData) return
      if(!carrier) return

      showSpinner()

      const fileSavePath = `${carrierSavesPath}/${carrier}/${fileUpload}.json`
      await containerService.getBlockBlobClient(fileSavePath).upload(JSON.stringify(saveData), JSON.stringify(saveData).length)
      notify('success', `${fileSavePath} saved!`)

      setWorkData(saveData)

      hideSpinner()
    }
    saveContent()
  }, [saveData, carrier])

  const handleItemsPerPageChange = useCallback((e) => {
    const value = e.target.value.trim() === '' ? 5 : +e.target.value
    setItemsPerPage(value)
  }, [])

  const handleFilterWarehouseChange = useCallback((e) => {
    setFilterWarehouse(e.target.value)
  }, [])

  const handleHideUnmatchedChange = useCallback((e) => {
    setHideUnmatched(e.target.checked)
  }, [])

  const handleExportXlsxClicked = useCallback((e) => {
    const exportXlsx = async () => {
      if(typeof currentFile === 'undefined') return
      if(typeof carrier === 'undefined') return
      if(typeof exportFiltered === 'undefined') return
      if(typeof exportFilteredTotals === 'undefined') return

      try {
        // Flatten the invoices data
        const flattenedInvoices = exportFiltered.map(invoice => flattenObject(invoice))
        
        // Convert flattened data to a worksheet
        const invoicesWorksheet = XLSX.utils.json_to_sheet(flattenedInvoices)
        const totalsWorksheet = XLSX.utils.json_to_sheet(exportFilteredTotals)
        
        const wb = { 
          Sheets: { 
            [`${carrier}-totals`]: totalsWorksheet,
            [`${carrier}-invoices`]: invoicesWorksheet
          }, 
          SheetNames: [
            `${carrier}-totals`,
            `${carrier}-invoices` 
          ] 
        }
        
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
        const blob = new Blob([excelBuffer], { type: xlsxFileType })
        const url = window.URL.createObjectURL(blob)
        setExportedFile(`${currentFile}.xlsx`)
        setExportedFileUrl(url)
        notify('success', 'Export completed! Download ready...')
      } catch (error) {
        console.error(error)
      }
    }
    exportXlsx()
    
  }, [carrier, currentFile, exportFiltered, exportFilteredTotals])

  const handleSearchChanged = useCallback((e) => {
    setSearchValue(e.target.value)
  }, [])

  const handleDifferenceSelectionChange = useCallback((e) => {
    setDifferenceType(e.target.value)
  }, [])

  const handleDifferenceValueChanged = useCallback((e) => {
    setDifferenceValue(e.target.value)
  }, [])

  const workView = useMemo(() => {
    if (!workData) return null

    showSpinner()
    
    const invoiceMap: Map<string, CarrierInvoice[]> = new Map()
    const uniqueCarrierClasses: string[] = []
    for(const invoice of workData.invoices){
      if(invoice.ShipDetail){
        const classs = invoice.ShipDetail.Shipping_Class__c.toLowerCase()
        if(classs.includes('express') || classs.includes('international')){
          continue
        }
        if(!uniqueCarrierClasses.includes(invoice.ShipDetail.Shipping_Class__c)){
          uniqueCarrierClasses.push(invoice.ShipDetail.Shipping_Class__c)
        }
        invoice.OrderNumber = invoice.ShipDetail.Order_Number__c
      }

      if(!invoiceMap.has(invoice.ShipDetail?.Order_Number__c ?? invoice.TrackingNumber)){
        invoiceMap.set(invoice.ShipDetail?.Order_Number__c ?? invoice.TrackingNumber, [])
      }
      invoiceMap.get(invoice.ShipDetail?.Order_Number__c ?? invoice.TrackingNumber).push(invoice)
    }

    const appliedFilters = []
    const filteredWorkData: GroupedCarrierInvoice[] = []
    for(const key of invoiceMap.keys()){
      const invoices = invoiceMap.get(key)
      const invoicesNetDueSum = invoices.reduce((total, i) => total + i.NetDueNumber, 0)

      /** Check filters */
      if(filterWarehouse !== 'all'){
        if(invoices[0].ShipDetail?.Warehouse__r?.Name !== filterWarehouse){
          continue //skip the invoice/order
        }
      }
      if(hideUnmatched === true && typeof invoices[0].ShipDetail === 'undefined'){
        continue
      } else if(hideUnmatched === false && typeof invoices[0].ShipDetail === 'undefined'){
        filteredWorkData.push({
          ...invoices[0],
          OrderNumber: invoices[0].OrderNumber.trim() ? invoices[0].OrderNumber : 'Unknown Sample',
          TrackingNumbers: [invoices[0].TrackingNumber],
          InvoiceNumbers: [`${invoices[0].InvoiceNumber} - $${invoices[0].NetDueNumber}`]
        })
        continue
      }
      /** Search fields for search text */
      if(typeof searchValue !== 'undefined' && searchValue.trim() !== ''){
        const searchLower = searchValue.toLowerCase()
        const foundInvoices = invoices.filter(i => {
          // Iterate over the object's keys and values
          return Object.keys(i).some(key => {
            const value = i[key]
            
            // Check if the value is a string, and perform the search
            if (typeof value === 'string' && value.toLowerCase().includes(searchLower)) {
              return true
            }
  
            // If value is an object or array, you can extend this check
            if (typeof value === 'object' && value !== null) {
              // You can recursively check within nested objects if needed
              return JSON.stringify(value).toLowerCase().includes(searchLower)
            }
            
            return false
          })
        })
        if(foundInvoices.length === 0){
          continue
        }
      }
      invoices[0].differenceResult = ''

      /** Percent greater than threshold or margin greater than threshold */
      if (typeof differenceValue !== 'undefined' && differenceValue.trim() !== '') {
        const invoicesNetDueSum = invoices.reduce((total, i) => total + i.NetDueNumber, 0)
        const budgetTotal = invoices[0].ShipDetail.budgetTotal
        const difference = invoicesNetDueSum - budgetTotal
        if(difference <= 0){
          continue
        }
        
        const differencePercent = Math.round((Math.abs(difference) / Math.abs(invoicesNetDueSum)) * 100)

        const percent = `Percent: ${differencePercent}% (Threshold: ${differenceValue}%)`
        const margin = `Margin: $${difference.toLocaleString()} (Threshold: $${differenceValue})`
        if (differenceType === 'percent' && differencePercent > +differenceValue) {
          invoices[0].differenceResult = percent
          invoices[0].differenceType = differenceType
          invoices[0].differenceValue = `${differencePercent}`
        }
  
        if (differenceType === 'margin' && difference > +differenceValue) {
          invoices[0].differenceResult = margin
          invoices[0].differenceType = differenceType
          invoices[0].differenceValue = `${difference.toLocaleString()}`
        }

        if(!invoices[0].differenceResult){
          continue
        }
      }

      filteredWorkData.push({
        Account: invoices[0].Account,
        InvoiceDate: invoices[0].InvoiceDate,
        InvoiceNumber: invoices[0].InvoiceNumber,
        NetDue: `$${invoicesNetDueSum.toFixed(2)}`,
        NetDueNumber: invoicesNetDueSum,
        OrderNumber: invoices[0].OrderNumber,
        Products: invoices[0].Products,
        TrackingNumbers: invoices.map(i => i.TrackingNumber),
        differenceResult: invoices[0].differenceResult,
        ShipDetail: invoices[0].ShipDetail,
        numberOfInvoices: invoices.length,
        InvoiceNumbers: invoices.map(i => `${i.InvoiceNumber} - $${i.NetDueNumber}`)
      })
    }

    const newTotals: WarehouseTotal[] = []
    for(const invoice of filteredWorkData){
      if(!invoice.ShipDetail?.Warehouse__r?.Name) continue

      let existing = newTotals.find(nt => nt.warehouseName === invoice.ShipDetail?.Warehouse__r?.Name)
      let isNew = false
      if(!existing){
        existing = {
          invoiceTotal: 0,
          productBudgetTotal: 0,
          warehouseName: invoice.ShipDetail.Warehouse__r.Name,
          difference: 0
        }
        isNew = true
      }
      existing.invoiceTotal += invoice.NetDueNumber
      existing.productBudgetTotal += invoice.ShipDetail?.budgetTotal ?? 0
      existing.difference = existing.invoiceTotal - existing.productBudgetTotal
      if(isNew){
        newTotals.push(existing)
      }
    }
    setExportFilteredTotals(newTotals)

    /** Populate filter text */
    if(filterWarehouse !== 'all'){
      appliedFilters.push(`Warehouse = ${filterWarehouse}`)
    } else {
      appliedFilters.push('Warehouse = All')
    }

    appliedFilters.push(`Hide Unmatched = ${hideUnmatched ? 'Yes' : 'No'}`)

    if(typeof searchValue !== 'undefined' && searchValue.trim() !== ''){
      appliedFilters.push(`Any field contains "${searchValue}"`)
    }

    if (typeof differenceValue !== 'undefined' && differenceValue.trim() !== '') {
      appliedFilters.push(`${differenceType === 'percent' ? 'Percent' : 'Margin'} is greater than ${differenceType === 'margin' ? '$' : '' }${differenceValue}${differenceType === 'percent' ? '%' : ''}`)
    }    
    
    const startIndex = (currentPage - 1) * itemsPerPage
    const paginatedData = filteredWorkData.slice(startIndex, startIndex + itemsPerPage)

    const rows = paginatedData.map((record, index) => {
      const orderNumber = (
        <td>
          {!record.ShipDetail?.Order_Number__c ? record.OrderNumber : record.ShipDetail?.Order_Number__c}
        </td>
      )
      const invoiceNumber = (
        <td style={{minWidth: '150px'}}>
          {record.InvoiceNumbers.map(i => {
            return (
              <>
                {i}
                <br />
              </>
            )
          })}
        </td>
      )
      const invoiceTrackingNumber = (
        <td>
          {record.TrackingNumbers.map(t => {
            return (
              <>
                <a 
                  href={`https://www.fedex.com/wtrk/track/?tracknumbers=${t}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {t}
                </a>
                <br />
              </>
            )
          })}
        </td>
      )
      const invoiceAccount = (
        <td>
          {record.Account}
        </td>
      )
      const invoiceDate = (
        <td>
          {record.InvoiceDate}
        </td>
      )
      const invoiceProducts = (
        <td>
          {record.Products}
        </td>
      )
      const invoiceNetDue = (
        <td>
          {record.NetDue}
        </td>
      )
      const mcShipCost = (
        <td>
          {!record.ShipDetail?.Shipping_Cost__c ? '' : `$${record.ShipDetail?.Shipping_Cost__c}`}
        </td>
      )
      const orderProducts = []
      for(const op of record.ShipDetail?.Order_Products__r ?? []){
        orderProducts.push(
          <div>
            {op.mcBudget}
          </div>
        )
      }
      const mcBudget = (
        <td>
          {record.ShipDetail?.budgetTotal ? `$${record.ShipDetail?.budgetTotal}` : ''}
          <br/>
          <Badge>{record.differenceResult}</Badge>
        </td>
      )
      const mcBudgetDetail = (
        <td>
          {orderProducts}
        </td>
      )
      const mcWarehouse = (
        <td>
          {record.ShipDetail?.Warehouse__r?.Name}
        </td>
      )
      const mcShipTo = (
        <td>
          {record.ShipDetail?.Shipping_State__c}
        </td>
      )
      const mcCarrier = (
        <td>
          {record.ShipDetail?.Shipping_Carrier__c} {record.ShipDetail?.Shipping_Class__c}
        </td>
      )
      let shipDetailTrackingUrl = ''
      if(['undefined', 'null'].includes(typeof record.ShipDetail)){
        shipDetailTrackingUrl = ''
      } else {
        shipDetailTrackingUrl = `https://www.fedex.com/wtrk/track/?tracknumbers=${record.ShipDetail?.Tracking_Number__c}`
      }
      let shipDetailTrackingAHref = undefined
      if(shipDetailTrackingUrl !== ''){
        shipDetailTrackingAHref = <a 
          href={shipDetailTrackingUrl}
          target="_blank" 
          rel="noopener noreferrer"
        >
          {record.ShipDetail?.Tracking_Number__c}
        </a>
      }
      
      let orderProductTrackingNumbers = undefined
      if(record.ShipDetail?.Order_Products__r && record.ShipDetail?.BatchName__c === 'Conveyable-Multi'){
        const eachOp = []
        for(const op of record.ShipDetail?.Order_Products__r ?? []){
          eachOp.push(<div>{op.Product_MPN__c} {op.Tracking_Number__c}</div>)
        }

        orderProductTrackingNumbers = (
          <div>
            <div>Conveyable-Multi Tracking</div>
            {eachOp}
          </div>
        )
      }

      const mcTrackingNumber = (
        <td>
          <div>{shipDetailTrackingAHref}</div>
          {orderProductTrackingNumbers}
        </td>
      )
      const mcOrderTime = (
        <td>
          {record.ShipDetail?.Order__r?.orderTimeString}
        </td>
      )

      let shipDetailUrl = ''
      if(['undefined', 'null'].includes(typeof record.ShipDetail)){
        shipDetailUrl = ''
      } else {
        shipDetailUrl = `${salesforceUrl}/${record.ShipDetail?.Id}`
      }

      let shipDetailAHref = undefined
      if(shipDetailUrl !== ''){
        shipDetailAHref = <a 
          href={shipDetailUrl}
          target="_blank" 
          rel="noopener noreferrer"
        >
          {record.ShipDetail?.Id}
        </a>
      }

      const mcShipDetailLink = (
        <td>
          {shipDetailAHref}
        </td>
      )
      return (
        <tr>
          {orderNumber}
          {invoiceNumber}
          {invoiceTrackingNumber}
          {invoiceAccount}
          {invoiceProducts}
          {invoiceDate}
          {invoiceNetDue}
          {mcShipCost}
          {mcBudget}
          {mcBudgetDetail}
          {mcWarehouse}
          {mcShipTo}
          {mcCarrier}
          {mcTrackingNumber}
          {mcOrderTime}
          {mcShipDetailLink}
        </tr>
      )
    })

    setExportFiltered(filteredWorkData)

    const totalPages = Math.ceil(filteredWorkData.length / itemsPerPage)

    let items = []
    const range = 2
    for (let number = 1; number <= totalPages; number++) {
      if (
        number === 1 || 
        number === totalPages || 
        (number >= currentPage - range && number <= currentPage + range)
      ) {
        items.push(
          <Pagination.Item
            active={number === currentPage}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      } else if (
        number === currentPage - range - 1 || 
        number === currentPage + range + 1
      ) {
        items.push(<Pagination.Ellipsis  />);
      }
    }

    let warehouseOptions = []
    for(const warehouse of uniqueWarehouses){
      warehouseOptions.push(
        <option value={warehouse}>{warehouse}</option>
      )
    }

    //Generate totals table
    let totalRows = []
    for(const total of newTotals){
      totalRows.push(
        <tr>
          <td>{total.warehouseName}</td>
          <td>${total.invoiceTotal.toLocaleString()}</td>
          <td>${total.productBudgetTotal.toLocaleString()}</td>
          <td>${(total.difference).toLocaleString()}</td>
        </tr>
      )
    }
    totalRows.push(
      <tr>
        <td>Total</td>
        <td>${newTotals.reduce((total, t) => total + t.invoiceTotal, 0).toLocaleString()}</td>
        <td>${newTotals.reduce((total, t) => total + t.productBudgetTotal, 0).toLocaleString()}</td>
        <td>${newTotals.reduce((total, t) => total + t.difference, 0).toLocaleString()}</td>
      </tr>
    )

    const totalsTable = (
      <>
        <Table striped bordered hover>
          <thead style={{backgroundColor: 'lightgray'}}>
            <tr>
              <th>Warehouse</th>
              <th>Invoice Total</th>
              <th>Budget Total</th>
              <th>Difference</th>
            </tr>
          </thead>
          <tbody>
            {totalRows}
          </tbody>
        </Table>
      </>
    )

    hideSpinner()

    return (
      <>
        <hr/>
        {/* Filters */}
        <div>
          <Container>
            <Row>
              <Col>
                <strong>Items Per Page</strong>
                <Form.Control onChange={handleItemsPerPageChange} defaultValue={itemsPerPage}></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check onChange={handleHideUnmatchedChange} defaultChecked={hideUnmatched} label='Hide Unmatched'></Form.Check>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label for='warehouseSelection' id='warehouseSelectionLabel'>Filter Warehouse</Form.Label>
                <Form.Select id='warehouseSelection' onChange={handleFilterWarehouseChange}>
                  <option value='all'>All</option>
                  {warehouseOptions}
                </Form.Select>
              </Col>
              <Col>
                <strong>Search Any Field</strong>
                <Form.Control onChange={handleSearchChanged} placeholder='Enter any value to search through all rows and values...'></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label for='differenceSelection' id='differenceSelectionLabel'>Difference Type <i>(Net Due minus Budget divided by Order Product Budget)</i></Form.Label>
                <Form.Select id='differenceSelection' onChange={handleDifferenceSelectionChange}>
                  <option value='percent'>Percent Difference Greater Than</option>
                  <option value='margin'>Margin of Error</option>
                </Form.Select>
                <Form.Label for='differenceValue' id='differenceValueLabel'>Percent/Margin</Form.Label>
                <Form.Control defaultValue={differenceValue} id='differenceValue' onChange={handleDifferenceValueChanged} placeholder='Enter a percent or margin to filter invoice differences...'></Form.Control>
              </Col>
            </Row>
          </Container>
        </div>

        <hr/>
        <div>
          <Button variant='success' onClick={handleExportXlsxClicked}>Export XLSX</Button><br/> 
          <Button disabled={typeof exportedFile === 'undefined'}>
            {!exportedFile ? 'Download Exported' :  <a download={exportedFile} href={exportedFileUrl} style={{color: '#ffffff'}}>Download Exported</a>}
          </Button>
        </div>
        <hr/>
        <div>
          {totalsTable}
        </div>
        <hr/>
        <div><b>{filteredWorkData.length.toLocaleString()}</b> filtered result(s) out of {workData.invoices.length.toLocaleString()} total.</div>
        <div><b>Filter(s)</b>: {appliedFilters.map((af, index) => (<Badge key={index} bg="secondary">{af}</Badge>))}</div>
        <div><b>Carrier Class(es)</b>: {uniqueCarrierClasses.map((ucc, index) => (<Badge key={index} bg="dark">{ucc}</Badge>))}</div>
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
          <Pagination>{items}</Pagination>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              {CarrierInvoiceTableHeaders.map((th, index) => (
                <th
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                  }}
                >
                  {th}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
          <Pagination>{items}</Pagination>
        </div>
      </>
    )
  }, [workData, currentPage, carrier, itemsPerPage, filterWarehouse, hideUnmatched, uniqueWarehouses, exportedFile, exportedFileUrl, searchValue, differenceValue, differenceType])

  const fullView = useMemo(() => {
    return (
      <>
        <h1>Carrier Invoice Audit</h1>
        <hr/>
        <h2>{carrier ? carrier.toUpperCase() : ''}</h2>
        {workData ? currentFile : ''}
        {!workData ? carrierSelectionView : ''}
        {!workData && carrier ? workModeView : ''}
        {!workData && carrier && workMode === 'new' && !fileUpload ? dragDropAreaView : ''}
        {!workData && carrier && workMode === 'load' ? loadFromSaveView : ''}
        {workData ? workView : ''}
      </>
    )
  }, [carrier, workMode, carrierSelectionView, workModeView, dragDropAreaView, loadFromSaveView, invoices, workData, workView, currentFile])

  return fullView

}

export default CarrierInvoiceAuditComponent