import { TableClient } from '@azure/data-tables'
import { InteractiveBrowserCredential } from '@azure/identity'
import { DateTime } from 'luxon'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { TENANT_ID, CLIENT_ID } from '../../authConfig'
import { DATA_SERVICES_TABLES, DATA_SERVICES_TABLES_DEV } from '../../common/common.constants'
import { BatchesTableRow } from './DesktopShipperBatchRecords.interfaces'
import { Button, CloseButton, Col, Container, Dropdown, Form, Row, Table } from 'react-bootstrap'
import CustomMenuComponent from '../../dropdowns/CustomMenuComponent'
import { Bar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import DesktopShipperBatchRunsModalComponent from './DesktopShipperBatchRunsModalComponent'
import { CommonProperties } from '../../common/common.interfaces'
import { useSpinner } from '../../spinner/SpinnerContext'
import { notify } from '../../notify/notify'
import DesktopShipperErrorModalComponent from './DesktopShipperErrorModalComponent'

Chart.register(...registerables)

interface DesktopShipperBatchRecordsComponentProperties extends CommonProperties {
  environment: string
}
interface SortOptions<T> {
  field: string
  order: string
}

function sortByField<T>(array: T[], options: SortOptions<T>): T[] {
  const { field, order } = options
  
  const compare = (a: T, b: T): number => {
    const aValue = extractDateValue(a[field])
    const bValue = extractDateValue(b[field])
    
    if (aValue < bValue) {
      return order === 'asc' ? -1 : 1
    }
    
    if (aValue > bValue) {
      return order === 'asc' ? 1 : -1
    }
    
    return 0
  };
  
  return array.slice().sort(compare);
}

function extractDateValue(value: any): Date | any {
  if (typeof value === 'string' && isDateTimeString(value)) {
    const [month, day, year] = value.split('-');
    const formattedDate = `${month}-${day}-${year}`;
    return new Date(formattedDate);
  }
  
  return value;
}

function isDateTimeString(value: string): boolean {
  const dateTimeRegex = /^\d{2}-\d{2}-\d{4} \d{1,2}:\d{2} [AP]M$/i;
  const dateRegex = /^\d{2}-\d{2}-\d{4}$/i;

  return dateTimeRegex.test(value) || dateRegex.test(value);
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

interface Dashboard {
  pendingLabelPrinted: number
  pendingBatchCreate: number
  pendingProcessing: number
  pendingRateAndShip: number
  inspectionRequired: number
  pendingPackToSuit: number
  aLocation: number
  bLocation: number
  cLocation: number
  aeLocation: number
  mhsLocation: number
  packToSuitLocation: number
}

const DesktopShipperBatchRecordsComponent = (props: DesktopShipperBatchRecordsComponentProperties) => {
  const { showSpinner, hideSpinner } = useSpinner();
  const [lastRefresh, setLastRefresh] = useState(DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS))
  const [rows, setRows]: [BatchesTableRow[], any] = useState(undefined)
  const [runs, setRuns] = useState(undefined)
  const [filters, setFilters] = useState([])
  const [filteredCount, setFilteredCount] = useState(0)
  const [dashboard, setDashboard]: [Dashboard, any] = useState(undefined)
  const [showChart, setShowChart] = useState(false)
  const [sortField, setSortField] = useState('shipDetailOrderTime')
  const [sortOrder, setSortOrder] = useState('desc')
  const [runModalVisible, setRunsModalVisible] = useState(false)
  const [errorModalVisible, setErrorModalVisible] = useState(false)
  const [errorRecord, setErrorRecord] = useState(undefined)
  const [salesforceUrl, setSalesforceUrl] = useState(undefined)

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })

  /** Loading */
  useEffect(() => {
    const load = async () => {
      showSpinner()
      try {
        setSalesforceUrl(props.environment === 'production' ? 'https://nethealthshopsllc.lightning.force.com' : 'https://nethealthshopsllc--testing.sandbox.lightning.force.com')

        /** Fill search by fields */
        const records: BatchesTableRow[] = []
        /** BROKEN  */
        const iterator = new TableClient(props.environment === 'production' ? DATA_SERVICES_TABLES : DATA_SERVICES_TABLES_DEV, 'batches', credential).listEntities<BatchesTableRow>().byPage()
        for await(const page of iterator){
          for await (const value of page.values()){
            value.batchProcess = !value.batchProcess ? 'Not Available' : value.batchProcess
            value.desktopShipperBatchId = !value.desktopShipperBatchId ? 'Not Available' : value.desktopShipperBatchId
            value.displayShipDetailOrderDate = DateTime.fromISO(value.shipDetailOrderTime).setZone('America/Chicago').toFormat('MM-dd-yyyy')
            value.displayShipDetailOrderTime = DateTime.fromISO(value.shipDetailOrderTime).setZone('America/Chicago').toFormat('MM-dd-yyyy t')
            
            records.push(value)
          }
        }
        setRows(records)
        let runsRecords = []
        const runsInterator = new TableClient(props.environment === 'production' ? DATA_SERVICES_TABLES : DATA_SERVICES_TABLES_DEV, 'batchRuns', credential).listEntities().byPage()
        for await(const page of runsInterator){
          for await (const value of page.values()){
            value.lastRunTime = DateTime.fromISO(value.timestamp).setZone('America/Chicago').toFormat('MM-dd-yyyy t')
            runsRecords.push(value)
          }
        }

        runsRecords = sortByField(runsRecords, {field: 'lastRunTime', order: 'desc'})
        setRuns(runsRecords)
      }catch(error){
        console.log(error)
      }
      hideSpinner()
    }
    load()
  }, [lastRefresh, props.environment])

  const handleFilterChanged = useCallback((e) => {
    const currentFilters = [...filters]
    if(!currentFilters.includes(e.target.id)){
      currentFilters.push(e.target.id)
    }
    setFilters(currentFilters)
  }, [filters])

  const handleSortChanged = useCallback((e) => {
    setSortField(e.target.id)
    if(sortOrder === 'asc'){
      setSortOrder('desc')
    } else if(sortOrder === 'desc'){
      setSortOrder('asc')
    } else {
      setSortOrder('asc')
    }
  }, [sortOrder, sortField])

  const handleStatusSelectionChanged = useCallback((e) => {
    const updateOrder = async () => {
      if(typeof e === 'undefined') return

      const valueSplit = e.target.value.split('|')
      const pKey = valueSplit[0]
      const rKey = valueSplit[1]
      const newStatus = valueSplit[2]
      const row: BatchesTableRow = rows.find(r => r.partitionKey === pKey && r.rowKey === rKey)
      if(confirm(`Are you sure you wish to update order [${row.destktopShipperOrderId} / ${row.shipDetailOrderNumber}] status to [${newStatus}]?`)){
        await new TableClient(props.environment === 'production' ? DATA_SERVICES_TABLES : DATA_SERVICES_TABLES_DEV, 'batches', credential).upsertEntity({...row, status: newStatus} as any, 'Replace')
        notify('success', `Order [${row.destktopShipperOrderId} / ${row.shipDetailOrderNumber}] status updated to [${newStatus}]`)
        setLastRefresh(DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS))
      } else {
        e.target.value = `${pKey}|${rKey}|${row.status}`
      }
    }
    updateOrder()
  }, [rows])

  const handleErrorClicked = useCallback((e) => {
    if(typeof e === 'undefined') return
    const valueSplit = e.target.id.split('|')
    const pKey = valueSplit[0]
    const rKey = valueSplit[1]
    setErrorRecord(rows.find(r => r.partitionKey === pKey && r.rowKey === rKey))
    setErrorModalVisible(true)
  }, [rows])

  const buildTable = useMemo(() => {
    if(typeof rows === 'undefined') return

    const filteredRows = rows.filter((item) => {
      let allMatch = true
      
      for(const filter of filters){
        const splitFilter = filter.split('|')
        const key = splitFilter[0]
        const value = splitFilter[1]

        if(`${item[key]}` !== `${value}`){
          allMatch = false
        } 
      }
      return allMatch
    })

    let sortedRows = filteredRows
    if(typeof sortField !== 'undefined' && typeof sortOrder !== 'undefined'){
      sortedRows = sortByField(filteredRows, {field: sortField, order: sortOrder})
    }

    const statuses = rows.map(r => r.status)
    const pendingBatchCreates = statuses.filter(r => r === 'pending-batch-create')
    const pendingProcessings = statuses.filter(r => r === 'pending-processing')
    const pendingRateAndShips = statuses.filter(r => r === 'pending-rateAndShip')
    const pendingLabelPrinteds = statuses.filter(r => r === 'pending-label-printed')
    const inspectionRequireds = statuses.filter(r => r === 'inspection-required')
    const pendingPackToSuit = statuses.filter(r => r === 'pending-packToSuit')

    const batchNames = rows.map(r => r.batchName)
    const aLocations = batchNames.filter(r => r === 'A-Warehouse-Conveyable')
    const bLocations = batchNames.filter(r => r === 'B-Warehouse-Conveyable')
    const cLocations = batchNames.filter(r => r === 'C-Warehouse-Conveyable')
    const aeLocations = batchNames.filter(r => r === 'AE-NonConveyable')
    const mhsLocations = batchNames.filter(r => r === 'MHS-Stands')
    const packToSuitLocations = batchNames.filter(r => r === 'PackToSuit')

    const uniqueOrderNumbers = rows.map(r => r.shipDetailOrderNumber).filter(onlyUnique)
    const uniqueDSOrderIds = rows.map(r => `${r.destktopShipperOrderId}`).filter(onlyUnique)
    const uniqueStatuses = statuses.filter(onlyUnique)
    const uniqueLocations = rows.map(r => r.warehouseLocation).filter(onlyUnique)
    const uniqueBatchProcesses = rows.map(r => r.batchProcess).filter(onlyUnique)
    const uniqueBatchNames = batchNames.filter(onlyUnique)
    const uniqueBatchIds = rows.map(r => r.desktopShipperBatchId).filter(onlyUnique)
    const uniqueDays = rows.map(r => r.displayShipDetailOrderTime).filter(onlyUnique)
    const uniqueDates = rows.map(r => r.displayShipDetailOrderDate).filter(onlyUnique)
    const uniqueMpns = rows.map(r => r.mpn).filter(onlyUnique)

    const sortText = typeof sortField === 'undefined' ? '' : typeof sortOrder === 'undefined' ? '' : sortOrder === 'asc' ? 'ASC' : 'DESC'

    const th = []
    th.push(<th key={`header_sdon`}>
      <div onClick={handleSortChanged} id='shipDetailOrderNumber' style={{cursor: 'pointer'}}>Order {sortField === 'shipDetailOrderNumber' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`shipDetailOrderNumberFilter`} items={uniqueOrderNumbers.map(r => { return <Dropdown.Item key={`${r}`} id={`shipDetailOrderNumber|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Order Number'></CustomMenuComponent></th>)
    th.push(<th key={`header_dsoi`}>
      <div onClick={handleSortChanged} id='destktopShipperOrderId' style={{cursor: 'pointer'}}>DS Order {sortField === 'destktopShipperOrderId' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`desktopShipperOrderIdFilter`} items={uniqueDSOrderIds.map(r => { return <Dropdown.Item key={`${r}`} id={`destktopShipperOrderId|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='DS Order Id'></CustomMenuComponent></th>)
    th.push(<th key={`header_status`}>
      <div onClick={handleSortChanged} id='status' style={{cursor: 'pointer'}}>Status {sortField === 'status' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`statusFilter`} items={uniqueStatuses.map(r => { return <Dropdown.Item key={`${r}`} id={`status|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Status'></CustomMenuComponent></th>)
    th.push(<th key={`header_sdot`}>
      <div onClick={handleSortChanged} id='shipDetailOrderTime' style={{cursor: 'pointer'}}>Order Date/Time  {sortField === 'shipDetailOrderTime' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`shipDetailOrderTimeFilter`} items={uniqueDays.map(r => { return <Dropdown.Item key={`${r}`} id={`shipDetailOrderTime|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Date'></CustomMenuComponent></th>)
    th.push(<th key={`header_date`}>
      <div onClick={handleSortChanged} id='shipDetailOrderDate' style={{cursor: 'pointer'}}>Order Date  {sortField === 'shipDetailOrderDate' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`shipDetailOrderDateFilter`} items={uniqueDates.map(r => { return <Dropdown.Item key={`${r}`} id={`shipDetailOrderDate|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Date'></CustomMenuComponent></th>)
    th.push(<th key={`header_mpn`}>
      <div onClick={handleSortChanged} id='mpn' style={{cursor: 'pointer'}}>MPN {sortField === 'mpn' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`mpnFilter`} items={uniqueMpns.map(r => { return <Dropdown.Item key={`${r}`} id={`mpn|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='MPN'></CustomMenuComponent></th>)
    th.push(<th key={`header_wl`}>
      <div onClick={handleSortChanged} id='warehouseLocation' style={{cursor: 'pointer'}}>Location {sortField === 'warehouseLocation' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`warehouseLocationFilter`} items={uniqueLocations.map(r => { return <Dropdown.Item key={`${r}`} id={`warehouseLocation|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Location'></CustomMenuComponent></th>)
    th.push(<th key={`header_bn`}>
      <div onClick={handleSortChanged} id='batchName' style={{cursor: 'pointer'}}>Batch Name {sortField === 'batchName' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`batchNameFilter`} items={uniqueBatchNames.map(r => { return <Dropdown.Item key={`${r}`} id={`batchName|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Batch Name'></CustomMenuComponent></th>)
    th.push(<th key={`header_bp`}>
      <div onClick={handleSortChanged} id='batchProcess' style={{cursor: 'pointer'}}>Batch Process {sortField === 'batchProcess' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`batchProcessFilter`} items={uniqueBatchProcesses.map(r => { return <Dropdown.Item key={`${r}`} id={`batchProcess|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Batch Process'></CustomMenuComponent></th>)
    th.push(<th key={`header_dsbi`}>
      <div onClick={handleSortChanged} id='desktopShipperBatchId' style={{cursor: 'pointer'}}>Batch ID {sortField === 'desktopShipperBatchId' ? `(${sortText})` : ''}</div>
      <CustomMenuComponent key={`desktopShipperBatchIdFilter`} items={uniqueBatchIds.map(r => { return <Dropdown.Item key={`${r}`} id={`desktopShipperBatchId|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Batch ID'></CustomMenuComponent></th>)
    th.push(<th key={`header_url`}>Salesforce</th>)
    th.push(<th key={'error'}>Error</th>)

    const tr = []
    for(const row of sortedRows){
      let statusSelect
      const options = []
      for(const status of ['pending-processing', 'pending-batch-create', 'pending-rateAndShip', 'pending-label-printed', 'inspection-required', 'pending-packToSuit']){
        options.push(<option id={`${row.partitionKey}|${row.rowKey}|${status}`} key={`${row.partitionKey}|${row.rowKey}|${status}`} value={`${row.partitionKey}|${row.rowKey}|${status}`}>{status}</option>)
      }
      statusSelect = (
        <Form.Select defaultValue={`${row.partitionKey}|${row.rowKey}|${row.status}`} key={`statusselect_${row.rowKey}`} onChange={handleStatusSelectionChanged}>
          {options}
        </Form.Select>
      )
      const td = []
      td.push(<td key={`sdon_${row.rowKey}`}>{row.shipDetailOrderNumber}</td>)
      td.push(<td key={`dsoi_${row.rowKey}`}>{row.destktopShipperOrderId}</td>)
      td.push(<td key={`status_${row.rowKey}`}>{statusSelect}</td>)
      td.push(<td key={`sdot_${row.rowKey}`}>{row.displayShipDetailOrderTime}</td>)
      td.push(<td key={`date_${row.rowKey}`}>{row.displayShipDetailOrderDate}</td>)
      td.push(<td key={`mpn_${row.rowKey}`}>{row.mpn}</td>)
      td.push(<td key={`wl_${row.rowKey}`}>{row.warehouseLocation}</td>)
      td.push(<td key={`bn_${row.rowKey}`}>{row.batchName}</td>)
      td.push(<td key={`bp_${row.rowKey}`}>{row.batchProcess ?? 'No Batch Yet'}</td>)
      td.push(<td key={`dsbi_${row.rowKey}`}>{row.desktopShipperBatchId}</td>)
      td.push(<td key={`url_${row.rowKey}`}><a href={`${salesforceUrl}/${row.shipDetailId}`}>Salesforce</a></td>)
      td.push(<td key={`error_${row.rowKey}`}>
          {row.status === 'inspection-required' ? <Button id={`${row.partitionKey}|${row.rowKey}|${row.status}`} onClick={handleErrorClicked} variant='danger'>Error</Button> : ''}
      </td>)
      tr.push(<tr style={{background: row.status === 'inspection-required' ? '#f09b90' : ''}}  key={`${row.rowKey}`}>{td}</tr>)
    }

    setFilteredCount(tr.length)
    const dashboard: Dashboard = {
      pendingBatchCreate: pendingBatchCreates.length,
      pendingLabelPrinted: pendingLabelPrinteds.length,
      pendingProcessing: pendingProcessings.length,
      pendingRateAndShip: pendingRateAndShips.length,
      inspectionRequired: inspectionRequireds.length,
      pendingPackToSuit: pendingPackToSuit.length,
      aeLocation: aeLocations.length,
      aLocation: aLocations.length,
      bLocation: bLocations.length,
      cLocation: cLocations.length,
      mhsLocation: mhsLocations.length,
      packToSuitLocation: packToSuitLocations.length
    }
    setDashboard(dashboard)

    return (
      <Table striped bordered hover>
        <thead>
          <tr>{th}</tr>
        </thead>
        <tbody>
          {tr}
        </tbody>
      </Table>
    )

  }, [rows, filters, handleFilterChanged, sortField, sortOrder, salesforceUrl])

  const handleFilterRemoved = useCallback((e) => {
    const currentFilters = [...filters]
    const newFilters = []
    for(const filter of currentFilters){
      if(filter.includes(e.target.id)) continue
      newFilters.push(filter)
    }
    setFilters(newFilters)
  }, [filters])

  const filterItems = useMemo(() => {
    const columns = []

    for(const filter of filters){
      columns.push(
        <Col md="auto" style={{border: 'solid darkgray 1px', borderRadius: '4px', marginLeft: '5px', padding: '3px', fontSize: '10px', background: '#F0F0F0'}}>
          <div style={{float: 'right'}}><CloseButton id={filter} onClick={handleFilterRemoved}/></div>
          <strong>{filter.split('|')[1]}</strong>
        </Col>
      )
    }
    return (
      <Container>
        <Row style={{padding: '7px'}}>
          {columns}
        </Row>
      </Container>
    )
  }, [filters, handleFilterRemoved])

  const buildDashboard = useMemo(() => {
    if(typeof dashboard === 'undefined') return    

    return (
      <div>
        <Container>
          <Row>
            <Col style={{height: '250px'}}>
            <Bar data={{          
              labels: [`Pending Rate & Ship [${dashboard.pendingRateAndShip}]`, `Pending Processing [${dashboard.pendingProcessing}]`, `Pending Batch Create [${dashboard.pendingBatchCreate}]`, `Pending Label Printed [${dashboard.pendingLabelPrinted}]`, `Inspection Required [${dashboard.inspectionRequired}]`, `Pending Pack To Suit [${dashboard.pendingPackToSuit}]`],
              datasets: [{
                label: 'Status',
                data: [dashboard.pendingRateAndShip, dashboard.pendingProcessing, dashboard.pendingBatchCreate, dashboard.pendingLabelPrinted, dashboard.inspectionRequired, dashboard.pendingPackToSuit],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(255, 205, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                  'rgb(255, 99, 132)',
                  'rgb(255, 159, 64)',
                  'rgb(255, 205, 86)',
                  'rgb(75, 192, 192)',
                  'rgb(54, 162, 235)',
                  'rgb(153, 102, 255)',
                  'rgb(201, 203, 207)'
                ],
                borderWidth: 1
              }]
            }} options={{
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }}></Bar>
            </Col>
            <Col style={{height: '250px'}}>
            <Bar data={{          
              labels: ['A-Warehouse-Conveyable', 'B-Warehouse-Conveyable', 'C-Warehouse-Conveyable', 'AE-NonConveyable', 'MHS-Stands', 'PackToSuit'],
              datasets: [{
                label: 'Batch Type',
                data: [dashboard.aLocation, dashboard.bLocation, dashboard.cLocation, dashboard.aeLocation, dashboard.mhsLocation, dashboard.packToSuitLocation],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(255, 205, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                  'rgb(255, 99, 132)',
                  'rgb(255, 159, 64)',
                  'rgb(255, 205, 86)',
                  'rgb(75, 192, 192)',
                  'rgb(54, 162, 235)',
                  'rgb(153, 102, 255)',
                  'rgb(201, 203, 207)'
                ],
                borderWidth: 1
              }]
            }} options={{
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }}></Bar>
            </Col>
          </Row>
        </Container>
       
        
      </div>
    )
  }, [dashboard])

  const closeRunsModal = useCallback((e) => {
    setRunsModalVisible(false)
  }, [])

  const closeErrorModal = useCallback((e) => {
    setErrorModalVisible(false)
    setErrorRecord(undefined)
  }, [])

  const display = useMemo(() => {
    return (
      <div>
        <DesktopShipperErrorModalComponent salesforceUrl={salesforceUrl} closeModal={closeErrorModal} modalVisible={errorModalVisible} record={errorRecord}></DesktopShipperErrorModalComponent>
        <DesktopShipperBatchRunsModalComponent closeModal={closeRunsModal} modalVisible={runModalVisible} records={runs}></DesktopShipperBatchRunsModalComponent>
        {showChart && buildDashboard} 
        <Button variant='success' onClick={()=> {setLastRefresh(DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS))}}>Refresh</Button>&nbsp; 
        <Button onClick={() => {setRunsModalVisible(true)}}>Show Runs</Button>&nbsp; 
        <Button onClick={() => {setShowChart(!showChart)}}>{showChart ? 'Hide Charts' : 'Show Charts'}</Button>&nbsp; 
        <Button onClick={() => {setFilters([])}}>Clear Filters</Button>&nbsp;
             
        <div style={{float: 'right'}}><em>Last Refreshed: {lastRefresh}</em></div>
        Filtered {filteredCount.toLocaleString()} row(s)
        {filterItems}
        {buildTable}
      </div>
    )
  }, [salesforceUrl, closeErrorModal, errorModalVisible, errorRecord, closeRunsModal, runModalVisible, runs, showChart, buildDashboard, setRunsModalVisible, setShowChart, setFilters, lastRefresh, filteredCount, filterItems, buildTable])

  
  return display
}

export default DesktopShipperBatchRecordsComponent